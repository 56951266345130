import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './components/Login'
import Footer from './components/Footer'
import Menu from './components/Menu'
import {Routes, Route} from "react-router-dom";
import CertificateIndex from "./components/CertificateIndex";
import CertificateCreate from "./components/CertificateCreate";
import CertificateEdit from "./components/CertificateEdit";
import CertificateDown from "./components/CertificateDown";
import BeneficiaryEdit from "./components/BeneficiaryEdit";
import CertificateDownload from "./components/CertificateDownload";
import ExpedientIndex from "./components/ExpedientIndex";
import ExpedientIndexManage from "./components/ExpedientIndexManage";
import ExpedientCreate from "./components/ExpedientCreate";
import ExpedientEdit from "./components/ExpedientEdit";
import ReportIndex from "./components/ReportIndex";
import CertificateImport from "./components/CertificateImport";
import CertificateImportDown from "./components/CertificateImportDown";
import CertificateImportUpdate from "./components/CertificateImportUpdate";
import { useCookies } from 'react-cookie';

function App() {

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  return (
      <>
        { cookies.token ? <Menu /> : ''}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/certificate/create" element={<CertificateCreate />} />
          <Route path="/certificate/:id" element={<CertificateEdit />} />
          <Route path="/certificate/down/:id" element={<CertificateDown />} />
          <Route path="/certificate/import" element={<CertificateImport />} />
          <Route path="/certificate/importdown" element={<CertificateImportDown />} />
          <Route path="/certificate/importupdate" element={<CertificateImportUpdate />} />
          <Route path="/beneficiary/:id" element={<BeneficiaryEdit />} />
          <Route path="/downloads" element={<CertificateDownload />} />
          <Route path="/expedient" element={<ExpedientIndex />} />
          <Route path="/expedient/create" element={<ExpedientCreate />} />
          <Route path="/expedient/:id" element={<ExpedientEdit />} />
          <Route path="/reports/" element={<ReportIndex />} />
          <Route path="/" element={<CertificateIndex />} />
          <Route path="/expedient/manage" element={<ExpedientIndexManage />} />
        </Routes>
        <Footer />
      </>
  );
}

export default App;
