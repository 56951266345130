import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {Link, useNavigate} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";

export default function CertificateIndex() {

	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [loading, setLoading] = useState("");
	const [ collectives, setCollectives ] = useState(null);

	const [ search, setSearch ] = useState("");
	const [ society, setSociety ] = useState("");
	const [ ini_date, setIniDate ] = useState("2024-01-01");
	const [ end_date, setEndDate ] = useState("2024-12-31");
	const [ state, setState] = useState(null);

	const [ certificates, setCertificates ] = useState(null);

	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const pageCount = Math.ceil(totalItems / itemsPerPage);

	useEffect(()=>{
		if( cookies.user === 'mya' ) {
			if (!cookies.token) {
				navigate("/login");
			}
			fetchCollective();
		}else{
			navigate("/");
		}
	},[]);

	const handleState = (event) => {
		setState(event.target.value);
	};
	const handleSociety = (event) => {
		setSociety(event.target.value);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		fetchCertificates(newOffset);
	};

	const fetchCollective = async () => {
		await axios.get('https://api-rnf.seguronline.es/collective',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCollectives(data);

		});
	};

	const fetchCertificates = async (offset) => {
		await axios.post("https://api-rnf.seguronline.es/certificate/filter/" + offset, {
			"search": search,
			"state": state,
			"society": society,
			"ini_date": ini_date,
			"end_date": end_date
		},{
			headers: {
				Authorization: `${cookies.token}`
			}
		}).then(({data}) => {
			setCertificates(data.certificate);
			setTotalItems(data.totalItems);
		});
	};


	const fetchExcel = async () => {
		await axios.post("https://api-rnf.seguronline.es/certificate/excel" , {
			"search": search,
			"state": state,
			"society": society,
			"ini_date": ini_date,
			"end_date": end_date
		},{
			responseType: 'blob', // important
			headers: {
				Authorization: `${cookies.token}`
			}
		}).then(({data}) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'certificados';
			link.href = url;
			link.setAttribute('download', name+'.xlsx');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del Excel");
		});
	};

	return (
		<Container>
			<br/>
			<h1>Lista de Certificados</h1>
			<br/>
			<Container>
				<Row>
					<Col>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Buscar:</Form.Label>
							<input placeholder="Búsqueda rápida" className="form-control" type="search" name="search"
								   value={search} onChange={(event) => {
								setSearch(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="iniDate">
							<Form.Label>Fecha de inicio:</Form.Label>
							<Form.Control type="date" min="2024-01-01" value={ini_date} onChange={(event) => {
								setIniDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="endDate">
							<Form.Label>Fecha de fin:</Form.Label>
							<Form.Control type="date" min="2024-01-01" value={end_date} onChange={(event) => {
								setEndDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select onChange={handleState} >
								<option value="">Todos...</option>
								<option value="1">Dados de alta</option>
								<option value="2">Dados de baja</option>
								<option value="3">Modificados</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="collective_id">
							<Form.Group controlId="formExpert">
								<Form.Label>Sociedad:</Form.Label>
								<Form.Select onChange={handleSociety}>
									<option value="">Todas...</option>
									{collectives ? collectives.map((collective, index) => (
										<option value={collective.id}>{collective.name}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
				</Row>
				{loading ? <Spinner animation="border" variant="success"/> :
					<Button variant="success" onClick={()=>fetchCertificates(0)}>Ver resultados</Button>}
				&nbsp; &nbsp;
				{loading ? <Spinner animation="border" variant="success"/> :
					<Button variant="secondary" onClick={fetchExcel}>Descargar Excel</Button>}
			</Container>
			<br/>
			<br/>
			{certificates ?
			<>
				<Table striped hover responsive
					   variant="light">
					<thead>
					<tr>
						<th>Nº persona</th>
						<th>Nombre</th>
						<th>NIF</th>
						<th>Sociedad</th>
					</tr>
					</thead>
					<tbody>
					{certificates ? certificates.map((certificate, index) => (

						<tr key={index}>
							<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.person_number}</Link></td>
							<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.name}</Link></td>
							<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.nif}</Link></td>
							<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.collectives.name}</Link></td>
						</tr>
					)) : <tr>
						<td colSpan={12}>Cargando</td>
					</tr>}
					</tbody>
				</Table>
				<br/>
				<Container>
					<ReactPaginate
						breakLabel="..."
						nextLabel="siguiente >"
						onPageChange={handlePageClick}
						pageRangeDisplayed={10}
						pageCount={pageCount}
						previousLabel="< anterior"
						marginPagesDisplayed={2}
						pageClassName="page-item text-success-emphasis"
						pageLinkClassName="page-link text-success-emphasis"
						previousClassName="page-item text-success-emphasis"
						previousLinkClassName="page-link text-success-emphasis"
						nextClassName="page-item text-success-emphasis"
						nextLinkClassName="page-link text-success-emphasis"
						breakClassName="page-item text-success-emphasis"
						breakLinkClassName="page-link text-success-emphasis"
						containerClassName="pagination text-success-emphasis"
						activeClassName="active text-success-emphasis"
						renderOnZeroPageCount={null}
					/>
				</Container>
			</> : ''}
		</Container>
	);
}